/** @format */

import qs from "qs";
import debugLib from "debug";

import ApiAdapter from "./ApiAdapter";
import { RequestAdapterInterface } from "./RequestAdapterInterface";
import { AuthResponseType } from "../services/auth";

const debug = debugLib("prima:core:adapter:auth");

type LoginResponse = {
    success: boolean;
    token: string | undefined;
};

export default class AuthAdapter<
    RequestConfigType = any
> extends ApiAdapter<RequestConfigType> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter);
    }

    public async ensureAPIKey(getApiKey: () => Promise<string>) {
        const headers = await this.requestAdapter.getDefaultRequestHeaders();

        if (headers.common.Authentication) {
            debug(
                "Authentication key is set - request should be authenticated!",
                headers.common.Auth
            );
            return;
        }

        debug("Auth key not set. Updating it.");
        this.setAPIKey(await getApiKey());
    }

    public setAPIKey(apiKey: string) {
        const options = {
            common: {
                Authorization: `bearer ${apiKey}`,
            },
        };

        this.requestAdapter.updateDefaultRequestHeaders(options);
    }

    public async login(
        username: string,
        password: string
    ): Promise<string | false> {
        const {
            data: { success, token },
        } = await this._post<LoginResponse>(
            "core/users/login",
            qs.stringify({
                username,
                password,
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );

        if (!success) {
            return false;
        }

        if (!token) {
            throw "Server did not return api token";
        }

        return token;
    }

    public async logout(): Promise<void> {
        await this._get("core/users/logout");
    }

    public async checkLogin(): Promise<AuthResponseType> {
        try {
            const { data } = await this._get<boolean>(
                "core/users/loggedin.json"
            );

            return {
                authenticated: data,
                error: null,
            };
        } catch (error) {
            return {
                authenticated: false,
                error,
            };
        }
    }
}
