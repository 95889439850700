import { IterableStorageBase } from "./storage";

import deepCloneAsync from "../helpers/deepCloneAsync";
import AuthAdapter from "../model/AuthAdapter";
import UserAdapter, { UserModel } from "../model/User";
import { RequestAdapterInterface } from "../model/RequestAdapterInterface";
import MemoryStorage from "./MemoryStorage";
import NanoEvents from "nanoevents"
import nanoid from "nanoid/non-secure"

export interface AuthEvents {
    login: boolean,
    logout: boolean,
    userDidUpdate: undefined,
}

export interface AuthResponseType {
    authenticated: boolean,
    error: any | null,
}

class AuthService<RequestConfigType> {
    private authAdapter: AuthAdapter<RequestConfigType>
    private userAdapter: UserAdapter<RequestConfigType>

    private data: {
        isAuthenticated: boolean,
        user: UserModel;
    };

    private emitter: NanoEvents<AuthEvents>

    private storage: IterableStorageBase;

    private cancelSubscriptionCbs: Map<keyof AuthEvents, Map<string, () => void>>

    constructor(adapter: RequestAdapterInterface<RequestConfigType>, storage?: IterableStorageBase) {
        this.data = {
            isAuthenticated: false,
            user: null,
        }

        this.storage = storage || new MemoryStorage()

        this.emitter = new NanoEvents<AuthEvents>()

        this.cancelSubscriptionCbs = new Map()

        this.authAdapter = new AuthAdapter(adapter)
        this.userAdapter = new UserAdapter(adapter)
    }

    public isAuthenticated() {
        return this.data.isAuthenticated
    }

    /**
     * Checks if the user is authenticated (Auth Token is available and valid)
     *
     * @return {Promise<AuthResponseType>} Promise containing true if authenticated or false if not
     */
    public validateAuthentication = async (): Promise<AuthResponseType> => {
        await this.authAdapter.ensureAPIKey(() => this.storage.getItem('auth_token'))

        const wasAuthenticated = this.data.isAuthenticated

        const checkLoginResponse = await this.authAdapter.checkLogin()
        this.data.isAuthenticated = checkLoginResponse.authenticated

        // only trigger user update if authentication state changed
        if (wasAuthenticated !== this.data.isAuthenticated) {
            // no need to await the update promise - clients get notified by an event
            // when the new object is available
            this.updateUser()

            if (!this.data.isAuthenticated) {
                this.logout()
            }
        }

        if (checkLoginResponse.error) {
            return {
                authenticated: false,
                error: checkLoginResponse.error
            }
        }

        return {
            authenticated: this.data.isAuthenticated,
            error: null,
        }
    }

    protected setUser(user: UserModel): void {
        if (this.data.user === null && user === null) {
            // nothing to do here - we'd just fire unnecessary events
            return
        }

        this.data.user = user

        this.emitter.emit("userDidUpdate", undefined)
    }

    /**
     * update cached user object
     *
     * @returns {Promise<void>} promise that resolves when fetching is done
     */
    protected updateUser(): Promise<void> {
        if (!this.isAuthenticated()) {
            this.setUser(null)
            return
        }

        return this.userAdapter.whoami().then((user) => {
            if (user === null) {
                // request was canceled
                return
            }

            this.setUser(user)
        })
    }

    public async getUser(noCache?: boolean): Promise<UserModel> {
        if (!this.isAuthenticated()) {
            return null;
        }

        if (noCache) {
            await this.updateUser()
        }

        return deepCloneAsync(this.data.user)
    }

    /**
     * Login with username and password
     * @param {string} username Username to log in with
     * @param {string} password Password to log in with
     *
     * @return {Promise<boolean>} Promise containing true if login successful or false if not
     */
    public async login(username: string, password: string): Promise<boolean> {
        const token = await this.authAdapter.login(username, password)

        if (token === false) {
            return false
        }

        this.authAdapter.setAPIKey(token)
        await this.storage.setItem('auth_token', token)

        const tokenWorks = await this.validateAuthentication()

        if (!tokenWorks.authenticated) {
            return false
        }

        this.data.isAuthenticated = true

        this.emitter.emit("login", true)

        await this.updateUser()

        return true
    }

    /**
     * Logout the User
     *
     * @return {Promise<void>} resolves when logout is complete.
     */
    public async logout(): Promise<void> {
        // reset internal state
        this.data.isAuthenticated = false
        this.setUser(null)

        // invalidate and remove auth token
        await this.authAdapter.logout()
        this.authAdapter.setAPIKey(null)

        await this.storage.removeItem('auth_token')

        this.emitter.emit("logout", true)
    }

    /**
     * Subscribe to event
     *
     * @param {string} event Which event to subscribe to
     * @param {Function} cb Function to call after login
     *
     * @return {string} Unique Id to cancel subscription
     */
    public subscribe(event: keyof AuthEvents, cb: (arg: boolean) => any): string {
        const fn = this.emitter.on(event, cb)

        const map: Map<string, () => void> = this.cancelSubscriptionCbs.has(event) ?
            this.cancelSubscriptionCbs.get(event)
            : new Map()

        let id;

        do {
            id = nanoid()
        } while (map.has(id))

        map.set(id, fn)

        this.cancelSubscriptionCbs.set(event, map)

        return id
    }

    /**
     * Unsubscribe from event
     *
     * @param {string} event Which event to subscribe to
     * @param {string} id
     */
    public unsubscribe(event: keyof AuthEvents, id: string): void {
        const map = this.cancelSubscriptionCbs.get(event)

        if (!map || !map.has(id)) {
            return
        }

        map.get(id)()
    }
}

export default AuthService
