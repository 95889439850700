/** @format */

import ApiAdapter from "./ApiAdapter";
import { DateTime } from "luxon";
import {
    RequestAdapterConfiguration,
    RequestAdapterInterface,
} from "./RequestAdapterInterface";

export interface Model {
    id?: number;
    created?: DateTime;
    modified?: DateTime;
}

export type ModelWithId<T> = T & {
    id: number;
};

export type Paginator = {
    paginator: {
        page: number;
        pageCount: number;
        count: number;
        perPage: number;
        sort: string;
        direction: "asc" | "desc";
    };
};

export interface ControllerAdapterOptions<A extends Model> {
    dateFields: Array<keyof A>;
}

export interface PaginationParams {
    page?: number;
    perPage?: number;
    sort?: string;
    direction?: "asc" | "desc";
}

const firstCharUppercase = (str: string) => {
    if (!str || str.length === 0) {
        return str;
    }

    return str[0].toUpperCase() + str.substr(1);
};

export default class ControllerAdapter<
    RequestAdapterConfig,
    T extends Model,
    P extends Paginator,
    AddModel = T
> extends ApiAdapter {
    readonly controller: string;
    readonly nameSingular: string;
    readonly namePlural: string;
    readonly options: ControllerAdapterOptions<T> = {
        dateFields: ["created", "modified"],
    };

    protected constructor(
        controller: string,
        nameSingular: string,
        namePlural: string,
        adapter: RequestAdapterInterface<RequestAdapterConfig>,
        options?: ControllerAdapterOptions<T>
    ) {
        super(adapter);

        this.controller = controller;
        this.nameSingular = nameSingular;
        this.namePlural = namePlural;

        this.options = {
            ...this.options,
            ...options,
        };
    }

    protected resolveUrl(action: string): string {
        return `${this.base}/${this.controller}/${action}`;
    }

    protected hydrateEntity(entity: any): ModelWithId<T> {
        if (!entity) {
            return entity;
        }

        this.options.dateFields.forEach((field: keyof T) => {
            const value = entity[field];

            if (value) {
                entity[field] = DateTime.fromISO(value);
            }
        });

        return entity;
    }

    public paginate(
        action: string,
        paginationParams?: PaginationParams,
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ): Promise<any> {
        const params = config && config.params ? config.params : {};

        const { page, perPage, direction, sort } = paginationParams || {};

        return this._get(action, {
            ...config,
            params: {
                ...params,
                page,
                limit: perPage,
                sort,
                direction,
            },
        });
    }

    public async all(
        paginationParams?: PaginationParams,
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ): Promise<P> {
        const upperCaseNamePlural = firstCharUppercase(this.namePlural);

        const {
            data: { paginator, ...rest },
        } = await this.paginate("index", paginationParams, config);

        // @ts-ignore
        return {
            [this.namePlural]: rest[this.namePlural].map((entity: any) => {
                return this.hydrateEntity(entity);
            }),
            paginator: paginator[upperCaseNamePlural],
        };
    }

    public async get(
        id: T["id"],
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ) {
        const { data } = await this._get<any>(`view/${id}`, config);

        data[this.nameSingular] = this.hydrateEntity(data[this.nameSingular]);
        return data;
    }

    public add(
        data: AddModel,
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ) {
        return this._post(`add`, data, config);
    }

    public edit(
        id: T["id"],
        data: AddModel,
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ) {
        return this._post(`edit/${id}`, data, config);
    }

    public delete(
        id: T["id"],
        config?: RequestAdapterConfiguration<RequestAdapterConfig>
    ) {
        return this._delete(`delete/${id}`, config);
    }
}
