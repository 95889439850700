/** @format */

import {
    RequestAdapterConfiguration,
    RequestAdapterInterface,
    RequestResponseType,
} from "./RequestAdapterInterface";

export default class ApiAdapter<RequestConfigType = any> {
    readonly base: string;
    protected requestAdapter: RequestAdapterInterface<RequestConfigType>;

    protected constructor(
        adapter: RequestAdapterInterface<RequestConfigType>,
        base?: string
    ) {
        this.requestAdapter = adapter;
        this.base = base || "";
    }

    protected resolveUrl(action: string): string {
        return `${this.base}/${action}`;
    }

    protected _get<D>(
        action: string,
        config?: RequestAdapterConfiguration<RequestConfigType>
    ): Promise<RequestResponseType<D>> {
        return this.requestAdapter.get(this.resolveUrl(action), config);
    }

    protected async _post<D>(
        action: string,
        data?: any,
        config?: RequestAdapterConfiguration<RequestConfigType>
    ): Promise<RequestResponseType<D>> {
        return await this.requestAdapter.post(
            this.resolveUrl(action),
            data,
            config
        );
    }

    protected async _put<D>(
        action: string,
        data?: any,
        config?: RequestAdapterConfiguration<RequestConfigType>
    ) {
        return await this.requestAdapter.put(
            this.resolveUrl(action),
            data,
            config
        );
    }

    protected async _delete<D>(
        action: string,
        config?: RequestAdapterConfiguration<RequestConfigType>
    ): Promise<RequestResponseType<D>> {
        return await this.requestAdapter.delete(
            this.resolveUrl(action),
            config
        );
    }
}
