(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("debug"), require("luxon"), require("qs"), require("nanoevents"));
	else if(typeof define === 'function' && define.amd)
		define(["debug", "luxon", "qs", "nanoevents"], factory);
	else if(typeof exports === 'object')
		exports["@interweberde/prima-core"] = factory(require("debug"), require("luxon"), require("qs"), require("nanoevents"));
	else
		root["@interweberde/prima-core"] = factory(root["debug"], root["luxon"], root["qs"], root["nanoevents"]);
})(global, function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__12__) {
return 